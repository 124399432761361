import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Section, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
			Головна | Arena Футбольний навчальний центр
			</title>
			<meta name={"description"} content={"Де досягаються вершини потенціалу"} />
			<meta property={"og:title"} content={"Головна | Arena Футбольний навчальний центр"} />
			<meta property={"og:description"} content={"Де досягаються вершини потенціалу"} />
			<link rel={"shortcut icon"} href={"https://ua.tryzubtraditions.com/img/5043402.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://ua.tryzubtraditions.com/img/5043402.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://ua.tryzubtraditions.com/img/5043402.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://ua.tryzubtraditions.com/img/5043402.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://ua.tryzubtraditions.com/img/5043402.png"} />
			<meta name={"msapplication-TileImage"} content={"https://ua.tryzubtraditions.com/img/5043402.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="160px 0 170px 0" background="linear-gradient(180deg,rgba(5, 7, 33, 0.9) 0%,rgba(5, 7, 33, 0.8) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://ua.tryzubtraditions.com/img/1.jpg) 0% 0% /cover repeat scroll padding-box" md-padding="80px 0 90px 0" quarkly-title="Hero-8">
			<Box
				min-width="100px"
				min-height="100px"
				lg-width="100%"
				display="flex"
				flex-direction="column"
				align-items="center"
			>
				<Text
					margin="0px 0px 20px 0px"
					font="normal 700 64px/1.2 --fontFamily-sansTrebuchet"
					sm-font="normal 700 42px/1.2 --fontFamily-sans"
					padding="0px 200px 0px 200px"
					text-align="center"
					color="--light"
					lg-padding="0px 0 0px 0"
				>
					Arena Футбольний центр{"\n\n"}
				</Text>
				<Text
					margin="0px 0px 50px 0px"
					font="--lead"
					color="#d4dae0"
					padding="0px 200px 0px 200px"
					text-align="center"
					lg-padding="0px 0 0px 0"
				>
					Де досягаються вершини потенціалу{"\n\n"}
				</Text>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					sm-flex-direction="column"
					sm-align-self="stretch"
				>
					<Button
						margin="0px 15px 0px 0px"
						padding="12px 28px 12px 28px"
						background="--color-primary"
						border-radius="8px"
						font="normal 400 17px/1.5 --fontFamily-sans"
						sm-margin="0px 0 15px 0px"
						transition="background-color 0.2s ease 0s"
						border-width="2px"
						border-style="#3f24d8"
						border-color="#3f24d8"
						hover-background="--color-secondary"
						href="/contacts"
						type="link"
						text-decoration-line="initial"
					>
						Зв'яжіться з нами
					</Button>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="#46728a">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 80px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 0px 0px" color="--orange" font="--lead" md-margin="0px 0px 20px 0px">
					Давайте пограємо разом
				</Text>
				<Text margin="0px 0px 0px 0px" color="--light" font="--headline2" md-margin="0px 0px 30px 0px">
					Про нас{"\n\n"}
				</Text>
			</Box>
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
			>
				<Text margin="0px 0px 40px 0px" color="--light" font="--base">
					Ласкаво просимо до вершини Фунтбельнийн-н'чалнянин,-вершина футбольної освіти, де перевірені часом методи, що синергізують із сучасними уявленнями.Розроблений як для любителів, так і для ветеранів, наша навчальна програма спрямована на допомогу вам досягти свого зеніту у світі футболу.{"\n\n"}
				</Text>
			</Box>
		</Section>
		<Section padding="40px 0 40px 0" background="#46728a" quarkly-title="Description-11">
			<Override
				slot="SectionContent"
				flex-wrap="wrap"
				max-width="1440px"
				align-items="flex-start"
				flex-direction="row"
			/>
			<Box border-color="--color-light" width="100%" border-width="0px 0px 1px 0px" border-style="solid" />
			<Box width="100%" margin="20px 0px 0px 0px">
				<Text
					margin="0px 0px 0px 0px"
					font="--headline1"
					color="--light"
					letter-spacing="-.05em"
					lg-font="normal 400 70px/1.1em &quot;Inter&quot;, sans-serif"
					sm-font="normal 400 40px/1.1em &quot;Inter&quot;, sans-serif"
				>
					Де пристрасть відповідає точності{"\n\n\n\n"}
				</Text>
			</Box>
			<Box
				margin="30px 0px 30px 0px"
				lg-margin="56px 0px 30px 0px"
				md-display="none"
				width="33.333%"
				padding="20px 20px 20px 20px"
			/>
			<Box
				width="33.333%"
				padding="20px 20px 20px 20px"
				margin="30px 0px 30px 0px"
				lg-margin="56px 0px 30px 0px"
				md-width="50%"
				sm-margin="20px 0px 20px 0px"
				sm-width="100%"
			>
				<Text margin="0px 0px 0px 0px" font="--headline3" color="--light">
					Основні моменти програми{"\n\n"}
				</Text>
				<Text margin="40px 0px 0px 0px" font="--base" color="--light">
					
					<Strong>
						{" "}Адаптивні дрилі:
					</Strong>
					{" "}Досвід тренувань, що розвивається з вашим прогресом, гарантуючи, що ви постійно оскаржуєте та вдосконалюєте.{"\n"}
					<br />
					
					<Strong>
						Плинність та форма:
					</Strong>
					{" "}Вдосконалюйте свою техніку та рух для спритності, точності та динамічного геймплей.{"\n"}
					<br />
					<Strong>
						Психічне майстерність:{" "}
					</Strong>
					Посилити свій психологічний край із сеансами, зосередженими на фокусі, стійкості та іграх.{"\n\n"}
				</Text>
			</Box>
			<Box
				sm-width="100%"
				width="33.333%"
				padding="20px 20px 20px 20px"
				margin="30px 0px 30px 0px"
				lg-margin="56px 0px 30px 0px"
				md-width="50%"
				sm-margin="20px 0px 20px 0px"
			>
				<Text margin="0px 0px 0px 0px" font="--headline3" color="--light">
					Навчальні модулі{"\n\n"}
				</Text>
				<Text margin="40px 0px 0px 0px" font="--base" color="--light">
					<Strong>
						{"\n"}Точність проходження:
					</Strong>
					{" "}Посилити свої навички розповсюдження та руху м'яча.{"\n"}
					<br />
					<Strong>
						Оборонна динаміка:{" "}
					</Strong>
					Оволодіння мистецтвом читання гри, очікування п’єс та нейтралізації загроз.{"\n"}
					<br />
					<Strong>
						Повітряна майстерність:{" "}
					</Strong>
					Підсилюйте свою доблесть у поєдинках, залпах та далекі постріли.{"\n\n"}
				</Text>
			</Box>
			<Box
				md-width="50%"
				sm-margin="20px 0px 20px 0px"
				sm-width="100%"
				width="33.333%"
				padding="20px 20px 20px 20px"
				margin="30px 0px 50px 0px"
				lg-margin="56px 0px 30px 0px"
			>
				<Text margin="0px 0px 0px 0px" font="--headline3" color="--light">
					В косплекс входять:{"\n\n"}
				</Text>
				<Text font="--base" color="--light" margin="40px 0px 0px 0px">
					
					<Strong>
						{" "}Стадіон:
					</Strong>
					{" "}Тренуйтеся серед природи для оптимальних показників та спокою.{"\n"}
					<br />
					<Strong>
						Тренажерний зал:
					</Strong>
					{" "}Сучасний заклад для оптимізації фізичної кондиціонування та готовності.{"\n\n"}
				</Text>
			</Box>
			<Box
				md-width="50%"
				sm-margin="20px 0px 20px 0px"
				sm-width="100%"
				width="33.333%"
				padding="20px 20px 20px 20px"
				margin="30px 0px 50px 0px"
				lg-margin="56px 0px 30px 0px"
			>
				<Text color="--light" margin="0px 0px 0px 0px" font="--headline3">
					Курси та семінари{"\n\n"}
				</Text>
				<Text margin="40px 0px 0px 0px" font="--base" color="--light">
					<Strong>
						{"\n"}Bootcamps:
					</Strong>
					{" "}Спеціалізовані навчальні табори, орієнтовані на піднесення конкретних аспектів вашої гри до професійних стандартів.{"\n"}
					<br />
					<Strong>
						Стратегії та сеанси спокою:
					</Strong>
					{" "}Займайтеся збагаченням дискусій про футбольну стратегію з подальшими методами релаксації для цілісного зростання.{"\n\n"}
				</Text>
			</Box>
		</Section>
		<Components.Contacts />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"652fe240cd81ee001f7dd8c4"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});